@import '../../assets/styles/variables';
@import '../../assets/styles/animations';

.flash-messages {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.flash-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  font-family: $open-sans;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid transparent;
  color: $white;
  box-shadow: 0 2px 4px 0 rgba($black, 0.5);
  text-align: left;

  &--opening {
    @extend %fadeInDown;
  }

  &--closing {
    @extend %fadeOutUp;
  }

  &--success {
    background: $caribbean-green;
    border-color: $caribbean-green;
  }

  &--warning {
    background: $web-orange;
    border-color: $web-orange;
  }

  &--error {
    background: $red;
    border-color: $red;
  }

  strong {
    font-weight: 700;
  }

  &__dismiss {
    transform: rotate(45deg);
    padding: 0;
    border: none;

    svg {
      display: block;
      width: 15px;
      height: 15px;
    }
  }
}
