@import 'fonts';
@import 'mixins';
@import 'normalize';
@import 'variables';

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html {
  box-sizing: border-box;
}

body {
  font-family: $open-sans;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  line-height: 1.4;
  overflow: hidden;
  overflow-y: auto;
}

main {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  font-family: inherit;
  color: inherit;
  cursor: pointer;
  appearance: none;
  background: transparent;
}

a,
button {
  &:focus {
    outline-offset: 1px;
    outline-color: $primary-color;
  }
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@include mobile {
  body {
    padding-top: $header-mobile-height;
  }
}

.label-required::after {
  content: '*';
  display: inline-block;
  top: -0.5em;
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  margin-left: 0.2em;
}
