@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: inherit;
  cursor: pointer;

  .link {
    &:hover {
      .icon {
        transform: none !important;
      }
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    color: inherit;
    transform: translate3d(0, 0, 0);
    pointer-events: none;
  }
  &__content {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    display: none;
    background-color: $white;
    padding: 20px;
    text-align: left;
    font-family: $open-sans;
    font-size: 14px;
    color: $tuatara;
    box-shadow: 0 0 30px rgba($black, 0.3);
    width: 250px;
    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }

    button {
      text-align: left;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    @include small {
      position: fixed;
      left: $mobile-side-margin !important;
      right: $mobile-side-margin !important;
      bottom: auto !important;
      top: 50%;
      width: (100% - $mobile-side-margin * 2) !important;
      transform: translate3d(0, -50%, 0);
      &::after {
        content: none;
      }
    }
  }

  &--top-left {
    .tooltip__content {
      bottom: 80%;
      margin-bottom: 15px;
      right: 50%;
      &::after {
        bottom: -15px;
        right: 0;
        border-width: 0 24px 15px 0;
        border-color: transparent $white transparent transparent;
      }
    }
  }

  &--top-right {
    .tooltip__content {
      bottom: 80%;
      margin-bottom: 15px;
      left: 50%;
      &::after {
        bottom: -15px;
        left: 0;
        border-width: 15px 24px 0 0;
        border-color: $white transparent transparent transparent;
      }
    }
  }

  &--bottom-left {
    .tooltip__content {
      top: 80%;
      margin-top: 15px;
      right: 50%;
      &::after {
        top: -15px;
        right: 0;
        border-width: 0 0 15px 24px;
        border-color: transparent transparent $white transparent;
      }
    }
  }

  &--bottom-right {
    .tooltip__content {
      top: 80%;
      margin-top: 15px;
      left: 50%;
      &::after {
        top: -15px;
        left: 0;
        border-width: 15px 0 0 24px;
        border-color: transparent transparent transparent $white;
      }
    }
  }

  &--active {
    .tooltip__content {
      display: inline-block;
      pointer-events: auto;
      z-index: 10;
    }
  }

  @include desktop {
    &:hover {
      .tooltip__content {
        display: inline-block;
        z-index: 10;
        pointer-events: auto;
      }
    }
  }
}
