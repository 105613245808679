@import '../../../../assets/styles/variables';

.autocomplete-field-container {
  position: relative;
}

.autocomplete-menu {
  z-index: 100;
  position: absolute;
  width: 100%;
  visibility: hidden;
  margin: 8px 0;
  text-align: left;
  background-color: #fff;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  border-radius: 4px;
  &--open {
    visibility: visible;
  }
  .icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    fill: $maya-blue;
  }
}
