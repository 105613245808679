@font-face {
  font-family: 'Open Sans';
  src:
    local('Open Sans Regular'),
    local('OpenSans-Regular'),
    url('../fonts/Open_Sans/OpenSans-Regular.woff2') format('woff2'),
    url('../fonts/Open_Sans/OpenSans-Regular.woff') format('woff'),
    url('../fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Bold';
  src:
    local('Open Sans Bold'),
    local('OpenSans-Bold'),
    url('../fonts/Open_Sans/OpenSans-Bold.woff2') format('woff2'),
    url('../fonts/Open_Sans/OpenSans-Bold.woff') format('woff'),
    url('../fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
