@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.link {
  display: inline-flex;
  align-items: center;
  font-family: $open-sans;
  font-size: 13px;
  line-height: 1.4;
  color: $smalt;
  transition: color 300ms ease;
  border: 0;
  padding: 0;

  &--strong {
    font-weight: bold;
  }

  @include mobile {
    margin-left: 10px;
  }

  &:hover {
    color: darken($smalt, 10);

    .icon:not(.icon--pdf):not(.icon--download) {
      transform: translateX(25%);
    }
  }

  &--reversed {
    .icon {
      transform: rotate(180deg);
    }

    &:hover {
      .icon:not(.icon--pdf):not(.icon--download) {
        transform: rotate(180deg) translateX(25%);
      }
    }
  }

  &--full {
    display: block;
    width: 100%;
    text-align: center;
  }

  .icon {
    margin-top: 2px;
    margin-right: 8px;
    width: 15px;
    height: 15px;
    flex: 0 0 auto;
    transition: transform 300ms ease, color 300ms ease;

    &--pdf {
      width: 15px;
      height: 20px;
    }
  }
}
