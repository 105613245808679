@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

select::-ms-expand {
  display: none;
}

// ETSG-472 -->
@-webkit-keyframes select-autofill {
  to {
    color: $mike-blue;
    background-color: transparent;
  }
}

select:-webkit-autofill {
  -webkit-animation-name: select-autofill;
  -webkit-animation-fill-mode: both;
}
// -->

.select {
  display: inline-block;
  position: relative;
  height: 45px;
  font-family: $open-sans;
  font-size: 20px;
  line-height: 25px;
  color: $secondary-color;
  background-image: linear-gradient(to right, $denim 33%, rgba($denim, 0) 0%);
  background-position: left bottom;
  background-size: 10px 2px;
  background-repeat: repeat-x;

  @include mobile {
    display: block;
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  &--empty {
    &,
    & + .field-wrapper__label {
      opacity: 0.3;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &__label {
    display: block;
    padding: 0 5px;
    font-family: $open-sans;
    font-size: 12px;
    color: $denim;
  }

  &__element {
    width: 100%;
    height: 100%;
    padding: 5px 20px 5px 5px;
    background: none;
    border: 0;
    appearance: none;
    color: inherit;

    &:focus {
      outline: none;
    }
  }

  &::after {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 35%;
    transform: translateY(-50%) translateX(-50%) rotate(135deg);
    height: 9px;
    width: 9px;
    content: '';
    border-style: solid;
    border-width: 1px 1px 0 0;
    vertical-align: top;
    pointer-events: none;
  }

  &--full {
    display: block;
    width: 100%;
  }

  &--error {
    color: $grenadier;
    background-image: linear-gradient(to right, $grenadier 33%, rgba($grenadier, 0) 0%);
  }

  &--disabled {
    color: $tuatara;
  }
}
