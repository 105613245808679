@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.flatpickr {
  &-rContainer,
  &-days {
    width: 100%;
  }

  &-calendar {
    width: 228px;
    margin-top: 5px;
    padding: 26px 31px;
    border: none;
    border-radius: 0;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

    &::before {
      position: absolute;
      margin: 0;
      top: -8px;
      left: auto;
      right: 0;
      width: 0;
      height: 0;
      content: '';
      border-style: solid;
      border-width: 0 0 8px 14px;
      border-color: transparent transparent $white transparent;
    }

    &::after {
      content: none;
    }

    &.arrowTop {
      &::before {
        border-bottom-color: $white;
      }
    }

    &.animate {
      &,
      &.open {
        animation: none;
      }
    }

    .dayContainer {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
      justify-content: flex-start;
    }
  }

  &-months {
    align-items: center;

    .flatpickr-month {
      height: auto;
      order: 0;
      text-align: left;
    }

    .flatpickr-prev-month {
      order: 1;
    }

    .flatpickr-next-month {
      order: 2;
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
      position: relative;

      &.disabled {
        display: block;
        opacity: 0.5;

        &:hover {
          svg {
            fill: $curious-blue;
          }
        }
      }

      &:hover {
        svg {
          fill: $secondary-color;
        }
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: $curious-blue;
      }
    }
  }

  &-current-month {
    position: static;
    height: auto;
    padding: 0;
    font-family: $open-sans;
    font-size: 11px;
    font-weight: bold;
    color: $curious-blue;
    text-transform: uppercase;
    text-align: left;

    input.cur-year {
      font-weight: bold;
    }
  }

  &-weekdays {
    margin: 5px 0 15px;
    height: auto;

    .flatpickr-weekday {
      font-size: 0;
      color: $pumica;
      text-transform: uppercase;
      width: 22px;
      flex: 0 0 auto;

      &:first-letter {
        font-size: 9px;
      }
    }
  }

  &-day {
    display: inline-flex;
    height: 22px;
    width: 22px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-family: $open-sans;
    font-size: 10px;
    font-weight: 600;
    color: $smalt;
    flex: 0 0 auto;

    &.today {
      color: $smalt;
      border-color: transparent;
    }

    &:hover,
    &.selected,
    &.today:hover {
      color: $curious-blue;
      border-color: $curious-blue;
      background: none;
    }

    &.prevMonthDay,
    &.disabled {
      &,
      &.disabled {
        opacity: 0;
        visibility: hidden;
        cursor: initial;
      }
    }

    &.nextMonthDay {
      display: none;
    }
  }
}
