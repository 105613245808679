@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.candidate-infos__phone {
  display: flex;

  @include desktop {
    flex: 0 0 calc(50% - 30px);
    margin: 5px 15px;
  }

  .field-wrapper {
    flex: 1 1 auto;

    @include desktop {
      margin: 0;
    }

    &:first-child {
      min-width: 65px;
      flex-grow: 0;

      @include desktop {
        width: 20%;
      }
    }

    & + .field-wrapper {
      margin-left: 10px;
    }
  }

  .select,
  .input--dotted {
    background-position-x: 5px;
  }
}
